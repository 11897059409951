import axios from 'axios';

const test_pay = true;
const test_shop = false;

export const Config = {
    STRIPE_PK: test_pay ? 'pk_test_O6rWYstJ7SobsZfeQltL0JGY' : 'pk_live_QKkzQFdDwLdgBURg0VuG3F8L00vVraf1Lz',

    POOL: {
        UserPoolId: 'ap-southeast-1_mbe1kYcyr',
        ClientId: '582ovfnak7120ivjn5n21779bc'
    },

    KEYS: {
        Guest: 'WHqp8hTc2T4HrqAISu2FuxQnUU1YQE13uxri96Gj',
        Customer: 'vqYK0f1nyZ8k93Za9khqX7sFwNTPBKLS7ba4Kd3Y'
    },

    API: {
        KEY: '2784e0a8-a695-4707-8ff8-1921d399b2cf',
        SEC: 'e3hLh8y6fjHu'
    },

    SHOP_ID: test_shop ? '33eb1125-1f9c-42ac-9035-251890a0a516' : '2784e0a8-a695-4707-8ff8-1921d399b2cf',
    BRANCH_ID: test_shop ? 'rCfPQ6diXOW' : 'e3hLh8y6fjHu',

    sliceable_category: [ 'X3fSbki', 'SiQhA6v', 'CNNA7ll' ],
    bagel_category: [ 'X3fSbki', 'SiQhA6v', 'CNNA7ll' ],

    DELIVERY: {
      SELFCOLLECT: 'self',
      DELIVERY: 'delivery'
    },
}

const URL = {
    menu: 'https://tkskg0sjcd.execute-api.ap-southeast-1.amazonaws.com/prod/menu',
    schedule: 'https://tkskg0sjcd.execute-api.ap-southeast-1.amazonaws.com/prod/schedule',
    order: 'https://tkskg0sjcd.execute-api.ap-southeast-1.amazonaws.com/prod/checkout',    // New API
    delivery: 'https://tkskg0sjcd.execute-api.ap-southeast-1.amazonaws.com/prod/delivery'
}

export const postRequest = (url, token, params, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': Config.KEYS.Customer
    };
  
    if (token) {
      headers['Authorization'] = token;
    }
  
    axios.post(url, JSON.stringify(params), {
      headers: headers
    }).then(res => {
      if (res.data.statusCode === 200) {
        callback(JSON.parse(res.data.body), null);
      } else {
        callback(null, JSON.parse(res.data.body));
      }
    });
  };
  
  export const getRequest = (url, token, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': Config.KEYS.Customer
    };
  
    if (token) {
      headers['Authorization'] = token;
    }
  
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (res.data.statusCode === 200) {
        callback(JSON.parse(res.data.body), null);
      } else {
        callback(null, JSON.parse(res.data.body));
      }
    });
  };
  
  export const getMenuRequest = (callback) => {
    axios.get(URL.menu, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Config.KEYS.Customer,
        'x-merchant-id': Config.SHOP_ID
      }
    }).then(res => {
      if (res.data.statusCode === 200) {
        callback(JSON.parse(res.data.body), null);
      } else {
        callback(null, JSON.parse(res.data.body));
      }
    });
};
  
export const getScheduleRequest = (callback) => {
  axios.get(URL.schedule, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': Config.KEYS.Customer,
      'x-branch-id': Config.BRANCH_ID
    }
  }).then(res => {
    if (res.data.statusCode === 200) {
      callback(JSON.parse(res.data.body), null);
    } else {
      callback(null, JSON.parse(res.data.body));
    }
  });
};

export const guestCheckoutRequest = (params, callback) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': Config.KEYS.Guest
  };

  axios.post(URL.order, JSON.stringify(params), {
    headers: headers
  }).then(res => {
    if (res.data.statusCode === 200) {
      callback(JSON.parse(res.data.body), null);
    } else {
      callback(null, JSON.parse(res.data.body));
    }
  });
};

export const getOrderRequest = (id, callback) => {
  axios.get(URL.delivery, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': Config.KEYS.Customer,
      'x-branch-id': Config.BRANCH_ID,
      'x-id': id
    }
  }).then(res => {
    if (res.data.statusCode === 200) {
      callback(JSON.parse(res.data.body), null);
    } else {
      callback(null, JSON.parse(res.data.body));
    }
  });
};
