import React, { Component } from 'react';
import queryString from 'query-string';
import QRCode from 'react-qr-code';
import { AppContext } from './Context';
import { getOrderRequest, Config } from './Api';

class OrderStatusPage extends Component {
    state = {
        id: '',
        showOrder: false,
        initOrder: false,
        data: {},
        stage: 0
    };

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const id = queries.id;
        this.setState({ id: id }, () => this.getOrder());
    }

    getOrder = () => {
        const { showLoading, hideLoading, showError } = this.context;

        getOrderRequest(this.state.id, (data, err) => {
            if (err) {
                showError(err);
            } else {
                hideLoading();
                this.setState({ data: data.order, stage: this.getOrderStage(data.order) });
                console.log('data', data.order);
            }
        });
    }

    getOrderStage = (order) => {
        if (order.delivered) {
            return 3;
        } else if (order.delivery_id) {
            return 2;
        } else {
            return 1;
        }
    }

    calculateItemSubtotal = (item) => {
		let subtotal = item.price * item.qty;
		return subtotal.toFixed(2);
	}

    render() {
        return (
            <div className="content white bg no-select">

                <div className="checkout-page row">
					<div className="checkout-form col-sm-7 col-md-8">
						<div className="checkout-form-content slide-up-fade-in">
                            <div className="checkout-form-card status-top">
								<div className="checkout-form-group status row">
                                    <img className={"col-xs-3" + (this.state.stage > 0 ? "" : " faded")} src={process.env.PUBLIC_URL + '/assets/staff.png'} />
                                    <img className={"col-xs-1" + (this.state.stage > 1 ? "" : " faded")} src={process.env.PUBLIC_URL + '/assets/arrow.png'} />
                                    <img className={"col-xs-3" + (this.state.stage > 1 ? "" : " faded")} src={process.env.PUBLIC_URL + '/assets/distance.png'} />
                                    <img className={"col-xs-1" + (this.state.stage > 2 ? "" : " faded")} src={process.env.PUBLIC_URL + '/assets/arrow.png'} />
                                    <img className={"col-xs-3" + (this.state.stage > 2 ? "" : " faded")} src={process.env.PUBLIC_URL + '/assets/foody.png'} />
                                </div>
                                <p>{this.state.stage === 0 ? "" : this.state.stage === 1 ? "Your order has been placed" : this.state.stage === 2 ? "Your order is on the way!" : this.state.stage === 3 ? "You have collected your order" : "Unknown status"}</p>
                            </div>

                            {this.state.data.adv_id && <div className="checkout-form-card">
								<div className="status-bottom hint">
                                    <p>Show this to our staff during collection</p>
                                </div>
								<div className="checkout-form-group row">
                                    <div className="col-md-6 qr">
                                        <QRCode value={`S&B:${Config.BRANCH_ID}:${this.state.id}`} />
                                    </div>
                                    <div className="col-md-6">
                                        <p>Order Number: <strong>{this.state.data.adv_id.toString().slice(-4)}</strong></p>
                                        <p>Location: <strong>{this.state.data.collection.place}</strong></p>
                                        <p>Time: <strong>{this.state.data.collection.details}</strong></p>
                                        <p>Payment: <strong>Payment Completed</strong></p>
                                    </div>
                                </div>
                            </div>}
                        </div>
					</div>

                    <div className="checkout-receipt hidden-xs col-sm-5 col-md-4">
						<div className="checkout-receipt-content animation-delay slide-up-fade-in">
							<h1>Your Cart</h1>

							{this.state.data.cart && this.state.data.cart.map((item, i) => (<div className="row">
								<div className="col-xs-8">
									<p><span className="cart-index">1. &nbsp;&nbsp;</span>{item.name}
									<span className="cart-item-quantity">&nbsp;&nbsp; x{item.qty}</span>
									</p>
								</div>
								<div className="col-xs-2 cart-item-price">
									<p>${this.calculateItemSubtotal(item)}</p>
								</div>
							</div>))}

							<hr />

							<div className="row">
								<div className="col-xs-9">
									<p>Subtotal
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>${this.state.data.price}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-xs-9">
									<p>Delivery Fees
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>$0.00</p>
								</div>
							</div>

							<div className="row">
								<div className="col-xs-9">
									<p>Discount
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>$0.00</p>
								</div>
							</div>

							<hr />

							<div className="row">
								<div className="col-xs-9">
									<p>Total
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>${this.state.data.price}</p>
								</div>
							</div>

						</div>
					</div>
                </div>

                {this.state.data.cart && <div id={this.state.initOrder ? (this.state.showOrder ? 'checkout-show' : 'checkout-init') : 'checkout'} className="checkout-items">
					<div className="checkout-bar row" onClick={() => this.setState({ showOrder: !this.state.showOrder, initOrder: true })}>
						<div className="col-xs-8">
							<h1>Your Order</h1>
						</div>
						<div className="col-xs-4">
							<h2>${this.state.data.price}</h2>
						</div>
					</div>
					<i className={`scroll-sym fa fa-angle-${this.state.showOrder ? 'down' : 'up'}`} 
						onClick={() => this.setState({ showOrder: !this.state.showOrder, initOrder: true })}></i>

					<br />

					<div id="checkout-cart" className="checkout-cart-items">

						{this.state.data.cart && this.state.data.cart.map((item, i) => (<div className="row">
							<div className="col-xs-8">
								<p><span className="cart-index">1. &nbsp;&nbsp;</span>{item.name}
								<span className="cart-item-quantity">&nbsp;&nbsp; x{item.qty}</span>
								</p>
							</div>
							<div className="col-xs-2 cart-item-price">
								<p>${this.calculateItemSubtotal(item)}</p>
							</div>
						</div>))}

						<hr />

						<div className="row">
							<div className="col-xs-9">
								<p>Subtotal
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>${this.state.data.price}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-xs-9">
								<p>Delivery Fees
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>$0.00</p>
							</div>
						</div>

						<div className="row">
							<div className="col-xs-9">
								<p>Discount
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>$0.00</p>
							</div>
						</div>

						<hr />

						<div className="row">
							<div className="col-xs-9">
								<p>Total
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>${this.state.data.price}</p>
							</div>
						</div>

					</div>

				</div>}
            </div>
        );
    }
}

OrderStatusPage.contextType = AppContext;
export default OrderStatusPage;