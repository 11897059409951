import React, { Component, createRef } from 'react';
import { Config, getMenuRequest } from './Api';
import { AppContext } from './Context';

const placeholderImg = "https://cdn.loveandlemons.com/wp-content/uploads/2020/05/bagel-recipe.jpg";

class MenuPage extends Component {
	state = {
		itemsActiveMouse: false,
		scrollLeft: 0,
		menu: [
			{ name: '', id: '0', items: [], visible: true }	// Default unselected
		],
		selectedCategory: '0',
		categoryItems: [],
		selectedItem: null,
		selectedItemSelector: { option: '', qty: 1 },
		showSideDetails: false
	}

	isMouseDown = false;
	startXScroll = null;
	scrollLeft = null;

	constructor() {
		super();
		this.menuBarRef = createRef();
	}

	componentDidMount() {
		this.getMenu();
	}

	getMenu = () => {
		const { showLoading, hideLoading, showError } = this.context;

		showLoading();
		getMenuRequest((data, err) => {
			if (err) {
				showError(err);
			} else {
				const menu = data.menu.filter(m => m.visible && m.items.length);
				this.setState({ 
					menu: menu,
					selectedCategory: menu.length ? menu[0].id : this.state.selectedCategory,
					categoryItems: menu.length ? menu[0].items : []
				});
			}
			this.setState({ loading: false });
			hideLoading();
		});
	}

	getCategoryMenu = (id) => {
		let menu = [];

		for (let i = 0; i < this.state.menu.length; i++) {
			if (this.state.menu[i].id === id) {
				return this.state.menu[i].items;
			}
		}

		return [];
	}

	addToCart = () => {
		/*if (item.custom && item.custom.length > 0) {
			item.option = []
			for (let j = 0; j < item.custom.length; j++) {
				for (let i = 0; i < this.state.menu.length; i++) {
					const s = this.state.menu[i].items.filter(it => it.id === item.custom[j]);
					if (s.length > 0) {
						item.option.push(s[0]);
						break;
					}
				}
			}
		}*/

		const { showError, addToCart } = this.context;

		const selectedItem = JSON.parse(JSON.stringify(this.state.selectedItem));

		if (Config.sliceable_category.indexOf(this.state.selectedCategory) > -1 && !this.state.selectedItemSelector.option) {
			// Handle sliceable item that user did not select any option for
			showError("Please choose if your bagel option");
		} else {
			if (Config.sliceable_category.indexOf(this.state.selectedCategory) > -1) {
				selectedItem.name += ` (${this.state.selectedItemSelector.option})`;
			}
			selectedItem.qty = this.state.selectedItemSelector.qty;
			delete selectedItem.custom;
			delete selectedItem.option;
			delete selectedItem.img;
			addToCart(selectedItem);
			this.closeSidePanel();
		}
	}

	closeSidePanel = () => {
		this.setState({ showSideDetails: false });
	}

	selectItem = (item) => {
		this.setState({ selectedItem: item, selectedItemSelector: { option: '', qty: 1 }, showSideDetails: true });
	}

	setSelectItemOption = (option) => {
		this.setState({ selectedItemSelector: { ...this.state.selectedItemSelector, option: option } })
	}

	setSelectItemQtyChange = (qty) => {
		let newQty = this.state.selectedItemSelector.qty + qty;
		this.setState({ selectedItemSelector: { ...this.state.selectedItemSelector, qty: newQty > 0 ? newQty : 1 } })
	}

	setSelectItemQty = (qty) => {
		this.setState({ selectedItemSelector: { ...this.state.selectedItemSelector, qty: qty > 0 ? qty : 1 } })
	}

	getSimilarInCart = (id) => {
		const { cart } = this.context;
		return cart.filter(item => item.id === id).reduce((a, b) => a + b.qty , 0);
	}
	
	render() {
		return (
			<div>
				<div id="menu" className="content white bg vert">
					<div className="row menu-container">

						{this.state.categoryItems.map((item, i) => (<div className="col-md-3 menu-border slide-up-fade-in" onClick={() => this.selectItem(item)}>
							<div className="menu-item white wiggle">
								<img src={item.img ? item.img : placeholderImg} />
								<div className="menu-item-content">
									<h1>{item.name}</h1>
									<h2>{item.desc}</h2>
									<h3>from ${parseFloat(item.price).toFixed(2)}</h3>
								</div>
								{this.getSimilarInCart(item.id) > 0 && <div className="menu-item-counter">
									<p>{this.getSimilarInCart(item.id)}</p>
								</div>}
							</div>
						</div>) )}

						<div className="col-md-12">
							<br />
							<br />
							<br />
						</div>

					</div>

					<div className="menu-nav grid-container no-scroll">
						<main className="grid-item menu-nav-main no-scroll">
							<div id="menu-slider" className={`items menu-outline-top` + (this.state.itemsActiveMouse ? ' active' : '')}
								ref={this.menuBarRef}
								onMouseDown={(e) => {
									this.isMouseDown = true;
									this.setState({ itemsActiveMouse: true });
									this.startXScroll = e.pageX - this.menuBarRef.current.offsetLeft;
									this.scrollLeft = this.menuBarRef.current.offsetLeft;
								}}
								onMouseLeave={(e) => {
									this.isMouseDown = false;
									this.setState({ itemsActiveMouse: false });
								}}
								onMouseUp={(e) => {
									this.isMouseDown = false;
									this.setState({ itemsActiveMouse: false });
								}}
								onMouseMove={(e) => {
									if (!this.isMouseDown) return;
									e.preventDefault();
									const x = e.pageX - this.menuBarRef.current.offsetLeft;
									const walk = (x - this.startXScroll) * 3; // Scroll-fast
									this.setState({ scrollLeft: this.scrollLeft - walk }, () => {
										this.menuBarRef.current.scrollLeft = this.state.scrollLeft;
									});
								}}
							>{
								this.state.menu.map(category => (
									<div className={`item ${this.state.selectedCategory === category.id ? 'item-selected' : ''}`}
										onClick={() => this.setState({ selectedCategory: category.id, categoryItems: this.getCategoryMenu(category.id) }) }
									>
										<p>{category.name}</p>
									</div>
								))
							}
							</div>
						</main>
					</div>
			</div>

			{this.state.selectedItem && <div id={this.state.showSideDetails ? 'slide' : 'slide-hide'} className="info info-menu-item">
				<div className="info-content info-content-vert no-select">
					<img className="slide-img" alt={this.state.selectedItem.name} src={this.state.selectedItem.img ? this.state.selectedItem.img : placeholderImg} />
					<div className="slide-container">
						<div className="slide-close" onClick={() => this.closeSidePanel()}><i class="fa fa-arrow-left"></i></div>
						<div className="slide-price wiggle"><h1>${parseFloat(this.state.selectedItem.price).toFixed(2)}</h1></div>
						<h1>{this.state.selectedItem.name}</h1>
						<p>{this.state.selectedItem.desc}</p>

						{Config.sliceable_category.indexOf(this.state.selectedCategory) > -1 && <div className="slide-option">
							<br/>
							<p className="slide-option-hint">Select Option</p>
							<button className={`button-${ this.state.selectedItemSelector.option === 'Plain' ? '' : 'un' }selected`} onClick={() => this.setSelectItemOption('Plain')}>Plain</button>
							<button className={`button-${ this.state.selectedItemSelector.option === 'Sea Salt & Pepper' ? '' : 'un' }selected`} onClick={() => this.setSelectItemOption('Sea Salt & Pepper')}>Sea Salt & Pepper</button>
							<button className={`button-${ this.state.selectedItemSelector.option === 'Sesame' ? '' : 'un' }selected`} onClick={() => this.setSelectItemOption('Sesame')}>Sesame</button>
						</div>}

						<div className="slide-option">
							<br/>
							<p className="slide-option-hint">Quantity</p>
							<button className="button-selected slide-option-large" onClick={() => this.setSelectItemQtyChange(-1)}><i class="fa fa-minus"></i></button>
							<input className="slide-input"
								placeholder="Qty"
								type="number"
								value={this.state.selectedItemSelector.qty}
								onChange={(e) => this.setSelectItemQty(parseInt(e.target.value, 10))}
							/>
							<button className="button-selected slide-option-large" onClick={() => this.setSelectItemQtyChange(1)}><i class="fa fa-plus"></i></button>
						</div>
						<div className="slide-complete">
							<br/>
							<br/>
							<button onClick={() => this.addToCart()}>Add to Cart</button>
						</div>
					</div>
				</div>
			</div>}
      </div>
		);
	}
}

MenuPage.contextType = AppContext;
export default MenuPage;