import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getScheduleRequest } from './Api';
import { AppContext } from './Context';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
		paddingLeft: 18,
		paddingRight: 18,
		border: 'none',
		borderRadius: 12,
		backgroundColor: state.isDisabled
			? null
			: state.isSelected
			? '#f47e07'
			: state.isFocused
			? '#CD7E2E'
			: null,
  }),
  control: (provided, state) => ({
		...provided,
		height: 56,
		border: 'none',
		borderRadius: 12,
		paddingLeft: 18,
		paddingRight: 18,
	}),
	indicatorSeparator: (provided, state) => ({
		border: 'none'
	}),
	placeholder: (provided, state) => ({
		color: 'black'
	}),
	menu: (provided, state) => ({
		border: 'none',
		borderRadius: 12,
		background: 'white',
	}),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}

function getMinOrderDate() {
	let now = new Date();
	now.setDate(now.getDate() + 3);		// Set 3 days advance order
	return now;
}

class Landing extends Component {
	state = {
		location: '',
		collectionDate: '',
		collectionTime: '',
		schedule: [],
		minOrderDate: getMinOrderDate(),
		dateShown: false
	}

	componentDidMount() {
		this.getSchedule();
	}

	getSchedule = () => {
		const { showLoading, hideLoading } = this.context;

		showLoading();
		getScheduleRequest((data, err) => {
			if (err) {
				this.context.showError(err);
			} else {
				this.setState({ schedule: data.schedule });
				console.log('test', data.schedule);
			}
			hideLoading();
		});
	}

	formatCustomTimeDigit = (time) => {
		return ('0' + time.toString()).slice(-2);
	}

	formatCustomStartTimeString = (id) => {
		let h = Math.floor(parseInt(id, 10) / 100);
		let m = parseInt(id, 10) % 100;
		return `${this.formatCustomTimeDigit(h)}:${this.formatCustomTimeDigit(m)}`;
	}

	formatCustomTimeId = (time) => {
		return (time.h * 100 + time.m).toString();
	}

	getTimeOptions = () => {
		if (!this.state.collectionDate) {
			return [];
		} else {
			let dayOfWeek = this.state.collectionDate.getDay();
			return [ ...new Set(this.state.schedule.map(s => 
				s.collections[dayOfWeek].map((c, i) => {
					return this.formatCustomTimeId(c.start)
				})
			).reduce((a, b) => a.concat(b)) ) ]
				.map(o => {
					return {
						label: this.formatCustomStartTimeString(o),
						value: o
					};
				})
				.sort(((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (b.label < a.label) {
						return 1;
					}
					return 0;
				}));
		}
	}

	getLocationOptions = () => {
		if (!this.state.collectionDate || !this.state.collectionTime) {
			return [];
		} else {
			let dayOfWeek = this.state.collectionDate.getDay();
			let startTime = this.state.collectionTime.value;
			return this.state.schedule.filter(s => s.collections[dayOfWeek].filter(c => (this.formatCustomTimeId(c.start) === startTime)).length > 0)
				.map(s => {
					return {
						label: s.name,
						value: s
					};
				});
		}
	}

	setDeliveryOption = () => {
		const { setDeliveryOption, showError } = this.context;
		
		if (!this.state.collectionDate || !this.state.collectionTime) {
			showError("Please select a valid date and time for delivery");
		} else if (!this.state.location) {
			showError("Please select a delivery location");
		} else {
			setDeliveryOption(
				this.state.collectionDate,
				this.state.collectionTime.value,
				this.state.location.value.loc_id,
			);

			this.props.history.push("/menu");
		}
	}

	linkToFacebook = () => {
        window.open('https://www.facebook.com/pg/sesamebagelssg','_newtab');
    }

    linkToInstagram = () => {
        window.open('https://www.instagram.com/sesamebagelssg/','_newtab');
	}
	
	toggleDateInput = (show) => {
		this.setState({ dateShown: show });
	}

	render() {
		return (
			<div id="landing" className="content bg">
				<div className="land-form row">
					
					<div className="col-sm-6 main-space high3 main-share form-horizontal slide-up-fade-in remove-bottom">
						<div className="form-group main-field" onClick={() => this.toggleDateInput(!this.state.dateShown)}>
							<i className="col-xs-2 fa fa-calendar"></i>
							<DatePicker 
								readOnly={true}
								open={this.state.dateShown}
								onClickOutside={() => this.toggleDateInput(false)}
								className="col-xs-10 date-input"
								timeInputLabel="Start Time"
								dateFormat="d/M/yyyy"
								placeholderText="Collection Date"
								popperPlacement="bottom"
								minDate={this.state.minOrderDate}
								selected={this.state.collectionDate}
								onChange={(value) => this.setState({ collectionDate: value, collectionTime: '' })} />
						</div>
					</div>
					
					<div className="col-sm-6 main-space high2 main-share form-horizontal slide-up-fade-in animation-delay remove-bottom">
						<div className="form-group main-field">
							<div className="col-xs-12 high main-no-pad-right">
								<Select 
									isSearchable={false}
									styles={customStyles}
									options={this.getTimeOptions()} 
									placeholder="Time"
									className="main-select"
									value={this.state.collectionTime}
									onChange={(e) => this.setState({ collectionTime: e })} />
							</div>
						</div>
					</div>

					<div className="col-xs-12 main-space high1 full-element slide-up-fade-in animation-delay-1">
						<Select 
							isSearchable={false}
							styles={customStyles}
							options={this.getLocationOptions()} 
							placeholder="Select Collection Place"
							className="main-select"
							value={this.state.location}
							onChange={(e) => this.setState({ location: e })} />
					</div>

					<div className="col-xs-12 slide-up-fade-in animation-delay-2">
						<button className="main-button full-button" onClick={() => this.setDeliveryOption()}>ORDER NOW</button>
					</div>
				</div>

			<div id="footer" className="slide-up-fade-in animation-delay-3">
				<div className="social-media-bar">
					<img className="social anim-button" onClick={() => this.linkToFacebook()} src={process.env.PUBLIC_URL + "/assets/facebook.png"} alt="Sesame & Bagels Facebook Page" />
					<img className="social anim-button" onClick={() => this.linkToInstagram()} src={process.env.PUBLIC_URL + "/assets/instagram.png"} alt="Sesame & Bagels Instagram Page" />
				</div>
			</div>
      </div>
		);
	}
}

Landing.contextType = AppContext;
export default withRouter(Landing);