import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import loader from './complete';
import { AppContext } from './Context';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
};

class SuccessPage extends Component {
    state = {
        id: ""
    };

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const id = queries.id;
        this.setState({ id: id });
        this.context.showSuccess("Your order has been placed. You will receive an email notification soon!");
    }

    gotoOrderPage = () => {
        this.props.history.push(`/order?id=${this.state.id}`)
    }

    render() {
        return (
            <div className="content white highest loader">
                <Lottie
                    options={defaultOptions}
                    height={120}
                    width={120}
                    eventListeners={[{
                        eventName: 'complete',
                        callback: () => this.gotoOrderPage()
                    }]}
                />
            </div>
        );
    }
}

SuccessPage.contextType = AppContext;
export default withRouter(SuccessPage);