import React, { Component } from 'react';
import LoadingImg from './loader.png'

class Loader extends Component {

    render() {
        return (
            <div className="content white highest loader">
                <img src={LoadingImg} alt="loading" />
            </div>
        );
    }
}

export default Loader;