import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from './Context';

class Header extends Component {
	
	//https://codepen.io/toddwebdev/pen/yExKoj
	render() {
		const { cart } = this.context;

		return(
			<div id="header">
				<div className="nav">
					<div className="logo-bar">
						<Link to="/">
							<img className="logo" src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="Sesame & Bagels Logo" />
						</Link>
					</div>
					<div className="mobile-button-group">
						<button className="nav-button" onClick={() => this.props.toggleNavPanel()}>
							<img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/menu.png"} alt="Sesame & Bagels Menu" />
						</button>
					</div>
					<div className="nav-button-group">
						<div className="nav-button-cart" onClick={() => this.props.toggleCart()}>
							<img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/cart.png"} alt="Sesame & Bagels Web Cart" />
							{cart.length > 0 && <p>{cart.length}</p>}
						</div>
						<Link to="menu">
							<img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/food.png"} alt="Sesame & Bagels Food Menu" />
						</Link>
						<Link to="/">
							<img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/home.png"} alt="Sesame & Bagels Home" />
						</Link>
						<div className="nav-end"></div>
					</div>
				</div>
			</div>
		);
	}
}

Header.contextType = AppContext;
export default Header;
