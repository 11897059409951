import React, { Component } from 'react';

class Footer extends Component {

	render() {
		return(
			<div></div>
		);
	}
}

export default Footer;
