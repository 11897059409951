import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from './Context';
import LoadingImg from './loader.png'

class MobileNav extends Component {

    navigateTo = (url) => {
        this.props.history.push(url);
        this.props.togglePanel();
    }

    linkToFacebook = () => {
        window.open('https://www.facebook.com/pg/sesamebagelssg','_newtab');
    }

    linkToInstagram = () => {
        window.open('https://www.instagram.com/sesamebagelssg/','_newtab');
    }

    render() {
        const { cart } = this.context;

        return (
            <div className="content white highest mobile-nav-button-menu">
                <div onClick={() => this.navigateTo("/home")}>
                    <div className="mobile-nav-button">
                        <img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/home.png"} alt="Sesame & Bagels Food Home" />
                        <h1>Home</h1>
                    </div>
                </div>
                <div onClick={() => this.navigateTo("/menu")}>
                    <div className="mobile-nav-button">
                        <img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/food.png"} alt="Sesame & Bagels Food Menu" />
                        <h1>Menu</h1>
                    </div>
                </div>
                <div onClick={() => this.props.toggleCart()}>
                    <div className="mobile-nav-button">
                        <img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/cart.png"} alt="Sesame & Bagels Food Web Cart" />
                        <h1>Cart</h1>
                        {cart.length > 0 && <h3>{cart.length}</h3>}
                    </div>
                </div>
                <div onClick={() => this.linkToFacebook()}>
                    <div className="mobile-nav-button">
                        <img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/facebook.png"} alt="Sesame & Bagels Facebook Social Media Page" />
                        <h1>Facebook</h1>
                    </div>
                </div>
                <div onClick={() => this.linkToInstagram()}>
                    <div className="mobile-nav-button">
                        <img className="nav-button anim-button" src={process.env.PUBLIC_URL + "/assets/instagram.png"} alt="Sesame & Bagels Instagram Social Media Page" />
                        <h1>Instagram</h1>
                    </div>
                </div>
            </div>
        );
    }
}

MobileNav.contextType = AppContext;
export default withRouter(MobileNav);