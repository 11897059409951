import React, { useContext } from 'react';
import {
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { AppContext } from './Context';

const CheckoutForm = (props) => {

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        if (error) {
            props.showError(error.message);
        } else {
            props.checkout(paymentMethod);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <br />
            <br />
            <div className="cart-complete">
                <button className="cart-submit-button" submit>Place Order</button>
            </div>
        </form>
    );
}

export default CheckoutForm;
