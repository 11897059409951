import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from './Context';

class Cart extends Component {

	gotoCheckout = () => {
		this.props.history.push('/checkout');
		this.props.closeCart();
	}

	formatPrice = (item) => {
		let subtotal = item.price * item.qty;
		return subtotal.toFixed(2);
	}

	formatCartTotal = (cart) => {
		let total = 0;

		cart.forEach(item => {
			total += item.price * item.qty;
		});

		return total.toFixed(2);
	}

	render() {
		const { cart, removeCartItem } = this.context;

		return (
			<div id={this.props.showCart ? 'slide' : 'slide-hide'} className="info cart high3">
				<div id="cart-slider" className="cart-content cart-content-btpad white">
					<h1 className="cart-close" onClick={() => this.props.closeCart()}><i className="fa fa-close"></i></h1>
					<h1>Your Cart</h1>
					<h2>Total: ${this.formatCartTotal(cart)}</h2>
					<hr />
					<div className="cart-items">

						{cart.map((item, i) => (<div className="row">
							<div className="col-xs-9">
								<p><span className="cart-index">{i+1}. &nbsp;&nbsp;</span>{item.name}
								<span className="cart-item-quantity">&nbsp;&nbsp; x{item.qty}</span>
								</p>
							</div>
							<div className="col-xs-2 cart-item-price">
								<p>${this.formatPrice(item)}</p>
							</div>
							<div className="col-xs-1 cart-item-delete" onClick={() => removeCartItem(i)}>
								<i className="fa fa-trash"></i>
							</div>
						</div>))}

					</div>
				</div>

				<div className="cart-button">
					<button onClick={() => this.gotoCheckout()}>Check Out</button>
				</div>
			</div>
		);
	}
}

Cart.contextType = AppContext;
export default withRouter(Cart);