import React, { Component } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import DatePicker from "react-datepicker";
import { loadStripe } from '@stripe/stripe-js';import {
    Elements
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { Config, getScheduleRequest, guestCheckoutRequest } from './Api';
import { AppContext } from './Context';

const stripePromise = loadStripe('pk_test_O6rWYstJ7SobsZfeQltL0JGY');

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

function getMinOrderDate() {
	let now = new Date();
	now.setDate(now.getDate() + 3);		// Set 3 days advance order
	return now;
}

class Checkout extends Component {
	state = {
		showOrder: false,
		initOrder: false,
		deliveryOption: {},
		name: "",
		email: "",
		phone: "",
		schedule: [],
		timeOptions: [],
		locationOptions: [],
		errorCheck: {},
		minOrderDate: getMinOrderDate(),
		dateShown: false
	}

	componentDidMount() {
		this.getSchedule();
	}

	getSchedule = () => {
		const { showLoading, hideLoading, showError, deliveryOption } = this.context;

		showLoading();
		getScheduleRequest((data, err) => {
			if (err) {
				showError(err);
			} else {
				this.setState({ schedule: data.schedule });
				console.log('test', data.schedule);
			}

			this.setState({ deliveryOption: {
				date: deliveryOption.date,
				time: deliveryOption.time,
				place: deliveryOption.place,
			} }, () => {
				this.setTimeOptions();
				this.setLocationOptions();
			});
			hideLoading();
		});
	}

	formatCustomTimeDigit = (time) => {
		return ('0' + time.toString()).slice(-2);
	}

	formatCustomStartTimeString = (start) => {
		return `${this.formatCustomTimeDigit(start.h)}:${this.formatCustomTimeDigit(start.m)}`;
	}

	formatCustomTimeRangeString = (start, end) => {
		return `${this.formatCustomStartTimeString(start)} - ${this.formatCustomStartTimeString(end)}`;
	}

	formatCustomTimeId = (start) => {
		return (start.h * 100 + start.m).toString();
	}

	setTimeOptions = () => {
		const { deliveryOption } = this.state;
		if (deliveryOption.date) {
			let dayOfWeek = deliveryOption.date.getDay();
			let options = [ ...new Set(this.state.schedule.map(s => 
				s.collections[dayOfWeek].map((c, i) => {
					return {
						label: this.formatCustomStartTimeString(c.start),
						value: this.formatCustomTimeId(c.start),
						start: c.start,
						end: c.end
					}
				})
			).reduce((a, b) => a.concat(b), []) ) ]
				.sort(((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (b.label < a.label) {
						return 1;
					}
					return 0;
				})
			);

			if (deliveryOption.time) {
				// Remove selected option if the time does not exist
				if (options.filter(c => (c.value === deliveryOption.time)).length === 0) {
					this.setState({ deliveryOption: {...deliveryOption, time: ""} });
					console.log('removeing')
				}
			}

			this.setState({ timeOptions: options });
		} else {
			this.setState({ timeOptions: [] });
		}
	}

	setLocationOptions = () => {
		const { deliveryOption } = this.state;
		if (deliveryOption.date && deliveryOption.time) {
			let dayOfWeek = deliveryOption.date.getDay();
			let startTime = deliveryOption.time;
			this.setState({ locationOptions: this.state.schedule.filter(s => s.collections[dayOfWeek].filter(c => this.formatCustomTimeId(c.start) === startTime).length > 0)
				.map(s => {
					return {
						label: s.name,
						value: s.loc_id,
						data: s
					};
				}) });
		} else {
			this.setState({ locationOptions: [] });
		}
	}

	validateEmail = (email) => {
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    		return true;
  		}
    	return false;
	}

	formatCollectionDetails = (selectedDate, time) => {
		const date = new Date(selectedDate.getTime());
		date.setHours(time.start.h, time.start.m, 0, 0);
		return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${time.start.h}:${('0'+time.start.m.toString()).slice(-2)} - ${time.end.h}:${('0'+time.end.m.toString()).slice(-2)}`;
	}

	checkout = (card) => {
		const { clearDeliveryOption, clearCart, showError, cart, showLoading, hideLoading } = this.context;

		if (cart.length === 0) {
			showError("Please include at least 1 item in your cart");
		} else if (!this.state.name) {
			showError("Please enter your name");
			this.setState({ errorCheck: { name: true } });
		} else if (!this.state.email) {
			showError("Please enter your email address");
			this.setState({ errorCheck: { email: true } });
		} else if (!this.validateEmail(this.state.email)) {
			showError("Please enter valid email address");
			this.setState({ errorCheck: { email: true } });
		} else if (!this.state.phone) {
			showError("Please enter your contact number");
			this.setState({ errorCheck: { phone: true } });
		} else if (!isValidPhoneNumber(this.state.phone)) {
			showError("Please enter valid phone number");
			this.setState({ errorCheck: { phone: true } });
		} else if (!this.state.deliveryOption.date) {
			showError("Please select delivery date");
		} else if (!this.state.deliveryOption.time) {
			showError("Please select delivery time");
		} else if (!this.state.deliveryOption.place) {
			showError("Please select delivery location");
		} else {
			showLoading();

			let dayOfWeek = this.state.deliveryOption.date.getDay();
			let deliveryEntry = this.state.schedule.filter(s => s.loc_id === this.state.deliveryOption.place)[0];
			let deliverySlot = deliveryEntry.collections[dayOfWeek].filter(e => this.formatCustomTimeId(e.start) === this.state.deliveryOption.time)[0];

			guestCheckoutRequest({
				key: Config.SHOP_ID,
				sec_key: Config.BRANCH_ID,
				src: card.id,
				cart: cart,
				discount: 0,
				customer: {
					name: this.state.name,
					phone: this.state.phone,
					email: this.state.email
				},
				collection: {
					date: this.state.deliveryOption.date.getTime(),
					location: this.state.deliveryOption.place,
					place: deliveryEntry.name,
					details: this.formatCollectionDetails(this.state.deliveryOption.date, deliverySlot)
				},
				price: this.calculateCartTotal(cart),
				points: 0
			}, (data, err) => {
				if (err) {
					console.log('err', err);
					showError(err);
					hideLoading();
				} else {
					clearDeliveryOption();
					clearCart();
					hideLoading();
					this.props.history.push(`/success?id=${data.id}`);
				};
				
			});
		}
	}

	calculateItemSubtotal = (item) => {
		let subtotal = item.price * item.qty;
		return subtotal.toFixed(2);
	}

	calculateCartTotal = (cart) => {
		let total = 0;

		cart.forEach(item => {
			total += item.price * item.qty;
		});

		return total.toFixed(2);
	}

	calculateTotalPayable = (cart) => {
		let total = 0;

		cart.forEach(item => {
			total += item.price * item.qty;
		});

		return total * 100;
	}

	toggleDateInput = (show) => {
		this.setState({ dateShown: show });
	}

	render() {
		const { deliveryOption, cart, removeCartItem, showError } = this.context;

		return (
			<div className="content white bg no-select">

				<div className="checkout-page row">
					<div className="checkout-form col-sm-7 col-md-8">
						<div className="checkout-form-content slide-up-fade-in">

							<div className="checkout-form-card">
								<h1>Personal Info</h1>
								<div className="checkout-form-group row">
									
									<div className={"col-xs-12 checkout-form-group-input" + (this.state.errorCheck.name ? " checkout-form-error" : "")}>
										<input className="col-xs-12" placeholder="Customer Name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
									</div>
									<div className={"col-xs-12 col-md-6 checkout-form-group-input" + (this.state.errorCheck.email ? " checkout-form-error" : "")}>
										<input className="col-xs-12" placeholder="Email Address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
									</div>
									<div className={"col-xs-12 col-md-6 checkout-form-group-input" + (this.state.errorCheck.phone ? " checkout-form-error" : "")}>
										<PhoneInput className="col-xs-12 checkout-phone" defaultCountry="SG" placeholder="Contact Number" value={this.state.phone} onChange={(value) => this.setState({ phone: value })} />
									</div>
								</div>

								<hr />

								<h1>Delivery Details</h1>
								<div className="checkout-form-group row">
								</div>

								<div className="checkout-form-group row">
									
									<div className="col-xs-12 col-md-6 checkout-form-group-input" onClick={() => this.toggleDateInput(!this.state.dateShown)}>
										<div className="date-w">
											<DatePicker
												readOnly={true}
												open={this.state.dateShown}
												onClickOutside={() => this.toggleDateInput(false)}
												placeholder="Collection Date" 
												timeInputLabel="Start Time"
												dateFormat="d/M/yyyy"
												placeholderText="Collection Date"
												popperPlacement="bottom"
												minDate={this.state.minOrderDate}
												selected={this.state.deliveryOption.date}
												onChange={(value) => this.setState({ deliveryOption: {...this.state.deliveryOption, date: value, time: "", place: "", timeOptions: [], locationOptions: []} }, () => this.setTimeOptions())} 
											/>
										</div>
									</div>
									<div className="col-xs-12 col-md-6 checkout-form-group-input">
										<select className="col-xs-12" value={this.state.deliveryOption.time} onChange={(e) => this.setState({ deliveryOption: {...this.state.deliveryOption, time: e.target.value, place: "", locationOptions: []} }, () => this.setLocationOptions())}>
											<option value="" selected disabled>Collection Time</option>
											{this.state.timeOptions.map(option => <option value={option.value}>{this.formatCustomStartTimeString(option.start)}</option>)}
										</select>
									</div>
									<div className="col-xs-12 checkout-form-group-input">
										<select className="col-xs-12" value={this.state.deliveryOption.place} onChange={(e) => this.setState({ deliveryOption: {...this.state.deliveryOption, place: e.target.value} })}>
											<option className="checkout-option-nosel" value="" selected disabled>Collection Point</option>
											{this.state.locationOptions.map(loc => (<option value={loc.value}>{loc.label}</option>))}
										</select>
									</div>
								</div>

								<hr />

								<h1>Payment Method</h1>
								<div className="checkout-form-group row">
									<Elements stripe={stripePromise}>
										<CheckoutForm showError={showError} checkout={this.checkout} />
									</Elements>
								</div>

								<br />
							</div>

						</div>
					</div>

					<div className="checkout-receipt hidden-xs col-sm-5 col-md-4">
						<div className="checkout-receipt-content animation-delay slide-up-fade-in">
							<h1>Your Cart</h1>

							{cart.map((item, i) => (<div className="row">
								<div className="col-xs-8">
									<p><span className="cart-index">1. &nbsp;&nbsp;</span>{item.name}
									<span className="cart-item-quantity">&nbsp;&nbsp; x{item.qty}</span>
									</p>
								</div>
								<div className="col-xs-2 cart-item-price">
									<p>${this.calculateItemSubtotal(item)}</p>
								</div>
								<div className="col-xs-2 cart-item-delete" onClick={() => removeCartItem(i)}>
									<i className="fa fa-trash"></i>
								</div>
							</div>))}

							<hr />

							<div className="row">
								<div className="col-xs-9">
									<p>Subtotal
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>${this.calculateCartTotal(cart)}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-xs-9">
									<p>Delivery Fees
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>$0.00</p>
								</div>
							</div>

							<div className="row">
								<div className="col-xs-9">
									<p>Discount
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>$0.00</p>
								</div>
							</div>

							<hr />

							<div className="row">
								<div className="col-xs-9">
									<p>Total
									</p>
								</div>
								<div className="col-xs-3 cart-item-price right">
									<p>${this.calculateCartTotal(cart)}</p>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div id={this.state.initOrder ? (this.state.showOrder ? 'checkout-show' : 'checkout-init') : 'checkout'} className="checkout-items">
					<div className="checkout-bar row" onClick={() => this.setState({ showOrder: !this.state.showOrder, initOrder: true })}>
						<div className="col-xs-8">
							<h1>Your Order</h1>
						</div>
						<div className="col-xs-4">
							<h2>${this.calculateCartTotal(cart)}</h2>
						</div>
					</div>
					<i className={`scroll-sym fa fa-angle-${this.state.showOrder ? 'down' : 'up'}`} 
						onClick={() => this.setState({ showOrder: !this.state.showOrder, initOrder: true })}></i>

					<br />

					<div id="checkout-cart" className="checkout-cart-items">

						{cart.map((item, i) => (<div className="row">
							<div className="col-xs-8">
								<p><span className="cart-index">1. &nbsp;&nbsp;</span>{item.name}
								<span className="cart-item-quantity">&nbsp;&nbsp; x{item.qty}</span>
								</p>
							</div>
							<div className="col-xs-2 cart-item-price">
								<p>${this.calculateItemSubtotal(item)}</p>
							</div>
							<div className="col-xs-2 cart-item-delete" onClick={() => removeCartItem(i)}>
								<i className="fa fa-trash"></i>
							</div>
						</div>))}

						<hr />

						<div className="row">
							<div className="col-xs-9">
								<p>Subtotal
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>${this.calculateCartTotal(cart)}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-xs-9">
								<p>Delivery Fees
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>$0.00</p>
							</div>
						</div>

						<div className="row">
							<div className="col-xs-9">
								<p>Discount
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>$0.00</p>
							</div>
						</div>

						<hr />

						<div className="row">
							<div className="col-xs-9">
								<p>Total
								</p>
							</div>
							<div className="col-xs-3 cart-item-price right">
								<p>${this.calculateCartTotal(cart)}</p>
								<br /><br />
							</div>
						</div>

						<br /><br />

					</div>

				</div>
			</div>
		);
	}
}

Checkout.contextType = AppContext;
export default Checkout;