import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Footer from './Footer';
import Landing from './Landing';
import MenuPage from './Menu';
import Cart from './Cart';
import Checkout from './Checkout';
import Loader from './Loader';
import MobileNav from './MobileNav';
import { Config } from './Api';
import { AppContext } from './Context';
import SuccessPage from './SuccessPage';
import OrderStatusPage from './OrderStatus';

class Router extends Component {
	state = {
		showCart: false,
		initCart: false,
		showNavPanel: false,
		loading: false,
		cart: [],
		delivery: Config.DELIVERY.SELFCOLLECT,
        cost: {
            order: 0.0,
            delivery: 0.0,
            total: 0.0,
            point: 0.0
        },
		user: {},
		deliveryOption: {},
		showLoading: () => { this.setState({ loading: true }) },
		hideLoading: () => { this.setState({ loading: false }) },
		showError: (message) => { toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT }) },
		showSuccess: (message) => { toast.dark(message, { position: toast.POSITION.BOTTOM_RIGHT }) },
		setDeliveryOption: (date, time, place) => { this.setState({
			deliveryOption: {
				date: date,		// Delivery date as Date object
				time: time,		// Delivery time as { h, m }
				place: place,	// Delivery place as location ID
			}
		}, () => console.log(this.state.deliveryOption)) },
		clearDeliveryOption: () => { this.setState({ deliveryOption: {} }) },
		addToCart: (item) => { this.setState({ cart: [...this.state.cart, item] }) },
		removeCartItem: (index) => { this.setState({ cart: [...this.state.cart.slice(0, index), ...this.state.cart.slice(index + 1)] }) },
		clearCart: () => { this.setState({ cart: [] }) }
	};

	toggleCart = () => {
		this.setState({ showCart: !this.state.showCart, initCart: true });
	}

	closeCart = () => {
		this.setState({ showCart: false });
	}

	toggleNavPanel = () => {
		this.setState({ showNavPanel: !this.state.showNavPanel });
	}

	toggleMobileCart = () => {
		this.toggleNavPanel();
		this.toggleCart();
	}

	render() {
		return (
			<main>
				<AppContext.Provider value={this.state}>
					<Header toggleCart={this.toggleCart} toggleNavPanel={this.toggleNavPanel} />
					<Switch>
						<Route path="/menu" component={MenuPage} />
						<Route path="/home" component={Landing} />
						<Route path="/checkout" component={Checkout} />
						<Route path="/success" component={SuccessPage} />
						<Route path= "/order" component={OrderStatusPage} />
						<Route path="/" component={Landing} />
					</Switch>
					{this.state.loading && <Loader />}
					<Footer />
					{this.state.initCart && <Cart showCart={this.state.showCart} closeCart={this.closeCart} />}
					{this.state.showNavPanel && <MobileNav toggleCart={this.toggleMobileCart} togglePanel={this.toggleNavPanel} />}
					<ToastContainer />
				</AppContext.Provider>
			</main>
		);
	};
}

export default Router;